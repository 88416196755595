const nifValidator = id => {
  const DNI_REGEX = /^(\d{8})([A-Z])$/
  const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/

  const validate = id => {
    // Ensure upcase and remove whitespace
    id = id.toUpperCase().replace(/\s/, '')

    const type  = getIdType(id)
    const valid = type === 'dni' ? validDNI(id) : validNIE(id)

    // return { type, valid }
    return valid
  }

  const getIdType = id => {
    if (id.match(DNI_REGEX)) return 'dni'
    if (id.match(NIE_REGEX)) return 'nie'
  }

  const validDNI = dni => {
    const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE'
    const letter = dni_letters.charAt( parseInt( dni, 10 ) % 23 )
    return letter == dni.charAt(8)
  }

  const validNIE = nie => {
    // Change the initial letter for the corresponding number and validate as DNI
    const prefixDigit = { 'X': 0, 'Y': 1, 'Z': 2 }
    let prefix = nie.charAt(0)
    prefix = prefixDigit[prefix]

    return validDNI(prefix + nie.substr(1))
  }

  return validate(id)
}

const ibanValidator = rawIban => {
  const iban = trim(rawIban.toUpperCase().replace(/\s/g, ''))

  if(iban.length !== 24 || iban.substring(0, 2) !== 'ES') return false

  const regex = new RegExp('\\d{22}')
  if(regex.test(iban.substring(2, 24))) {
    const char1 = iban.substring(0, 1)
    const char2 = iban.substring(1, 2)
    const char1Code = getCharCode(char1)
    const char2Code = getCharCode(char2)

    let isbanaux = String(char1Code) + String(char2Code) + iban.substring(2)
    isbanaux = isbanaux.substring(6) + isbanaux.substring(0,6)
    const remainer = module97(isbanaux)
    return remainer === 1
  }

  function trim(str) {
    return str.replace(/^\s+/g,'').replace(/\s+$/g,'')
  }

  function getCharCode(char) {
    const abc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    return abc.search(char) + 10
  }

  function module97(banaux) {
    let parts = Math.ceil(banaux.length/7)
    let remainer = ''

    for (let i = 1; i <= parts; i++) {
      remainer = String(parseFloat(remainer + banaux.substr((i-1)*7, 7)) % 97)
    }

    return parseInt(remainer)
  }
}

const cifValidator = (cif) => {
  if (!cif || cif.length !== 9) return false


  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  let digits = cif.substr(1, cif.length - 2)
  const letter = cif.substr(0, 1)
  let control = cif.substr(cif.length - 1)
  let sum = 0
  let digit

  if (!letter.match(/[A-Z]/)) return false

  let i
  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i])

    if (isNaN(digit)) return false

    if (i % 2 === 0) {
      digit *= 2

      if (digit > 9) digit = parseInt(`${digit / 10}`) + (digit % 10)
    }

    sum += digit
  }

  sum %= 10
  digit = sum !== 0 ? 10 - sum : sum

  if (letter.match(/[ABEH]/))   return String(digit)  === control
  if (letter.match(/[NPQRSW]/)) return letters[digit] === control

  return String(digit) === control || letters[digit] === control
}

const cifAndNifValidator = (id) => {
  return cifValidator(id) || nifValidator(id)
}

export const validate = {
  cif: cifValidator,
  cifandnif: cifAndNifValidator,
  iban: ibanValidator,
  nif: nifValidator
}
